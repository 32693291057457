import * as React from "react"
import { useState, useEffect } from "react"
import { Dropdown, Modal, Form } from "react-bootstrap"
import axios from "axios"
import AxiosInstance from "../../../../services/axiosInstance"

const OutsideIdealWorkFlowModel = ({
  showOutsideWorkFlowModal,
  setShowOutsideWorkFlowModal,
  transactionTypeId,
  workFlowEditData,
  getTransactionDetail,
}) => {
  const [icons, setIcons] = useState([])
  const [dropdownIcon, setDropdownIcon] = useState("house-door-fill")

  const getIcons = () => {
    const unInterceptedAxiosInstance = axios.create()
    unInterceptedAxiosInstance.get(`${process.env.GATSBY_API_URL}/icon/list`).then(response => {
      setIcons(response.data.data)
    })
  }

  const handleClose = () => {
    setShowOutsideWorkFlowModal(false)
  }

  const handleAdditionalWorkFlow = e => {
    e.preventDefault()

    const formData = new FormData(e.target)

    let workFlowEditId = workFlowEditData ? workFlowEditData.id : ""
    let transactionTypeLabel = formData.get("title")

    if (!transactionTypeLabel) {
      alert("Please add Transaction Type Title.")
      return false
    }

    let url = `/settings/transaction-type/add-status/${transactionTypeId}`
    if (workFlowEditId) {
      url = `/settings/transaction-type/edit-status/${workFlowEditId}`
    }

    AxiosInstance.post(url, {
      icon: dropdownIcon,
      label: transactionTypeLabel,
      additionalType: true,
      color: "",
    })
      .then(response => {
        getTransactionDetail(transactionTypeId)
        handleClose()
      })
      .catch(error => {
        console.log(error)
      })
  }

  useEffect(() => {
    getIcons()
  }, [])

  const CustomMenu = React.forwardRef(({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
    const [value, setValue] = useState("")
    return (
      <div ref={ref} style={style} className={className} aria-labelledby={labeledBy}>
        <Form.Control
          autoFocus
          className="mx-3 my-2 w-75"
          placeholder="Search"
          onChange={e => setValue(e.target.value.toLowerCase())}
          value={value}
        />
        <ul className="list-unstyled">
          {React.Children.toArray(children).filter(
            child => !value || child.props.value.toLowerCase().startsWith(value)
          )}
        </ul>
      </div>
    )
  })

  return (
    <Modal show={showOutsideWorkFlowModal} onHide={handleClose}>
      <form method="post" onSubmit={handleAdditionalWorkFlow}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
              <i className="bi bi-pencil-fill" /> Stage Outside Ideal Workflow
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleClose}
            />
          </div>

          <div className="modal-body">
            <div className="mb-3 row align-items-center">
              <div className="col-sm-12">
                <label className="col-form-label">Title</label>
                <div className="input-group flex-nowrap">
                  {
                    <Dropdown>
                      <Dropdown.Toggle variant="" id="dropdown-basic" className="btn-default">
                        <i
                          className={`bi bi-${
                            workFlowEditData && workFlowEditData.icon ? workFlowEditData.icon : dropdownIcon
                          }`}
                        />
                      </Dropdown.Toggle>
                      <Dropdown.Menu as={CustomMenu} style={{ height: "250px", overflowY: "scroll" }}>
                        {icons.map(icon => {
                          return (
                            <Dropdown.Item
                              key={icon.icon}
                              value={icon.icon}
                              href="#"
                              onClick={() => setDropdownIcon(icon.icon)}
                            >
                              <i className={`bi bi-${icon.icon}`} />
                            </Dropdown.Item>
                          )
                        })}
                      </Dropdown.Menu>
                    </Dropdown>
                  }

                  <input
                    type="text"
                    className="form-control"
                    name="title"
                    id="title"
                    defaultValue={workFlowEditData ? workFlowEditData.label : ""}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-default" data-bs-dismiss="modal" onClick={handleClose}>
              <i className="bi bi-x-lg" /> Close
            </button>
            <button type="submit" className="btn btn-primary">
              <i className="bi bi-check2" /> Save
            </button>
          </div>
        </div>
      </form>
    </Modal>
  )
}

export default OutsideIdealWorkFlowModel
