import * as React from "react"
import PrivateRoute from "../../../../components/privateRoute"
import Detail from "../../../../components/Settings/Business/Jobtype/detail"
import ReduxWrapper from "../../../../redux/reduxWrapper"

const TransactionTypeDetail = props => {
  return <PrivateRoute component={Detail} location={props.location} selectedId={props.id} />
}

const WrappedPage = props => <ReduxWrapper element={<TransactionTypeDetail {...props} />} />
export default WrappedPage
