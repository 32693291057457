import * as React from "react"
import { Link } from "gatsby"
import { useEffect, useState } from "react"
import Layout from "../../../Layout/layout"
import Seo from "../../../seo"
import { Accordion, OverlayTrigger, Tooltip, Dropdown } from "react-bootstrap"
import "./settings_jobtype.css"
import AxiosInstance from "../../../../services/axiosInstance"
import { VIEW_STATE_HIDE, VIEW_STATE_OPEN, isShowState } from "../../../../services/globalHelpers"
import WorkFlowIconColorModal from "./workFlowIconColorModal"
import Tags from "./tags"
import IdealWorkFlowModel from "../../AboutYourBusiness/Project/idealWorkFlowModel"
import OutsideIdealWorkFlowModel from "../../AboutYourBusiness/Project/outsideIdealWorkFlowModel"

const Detail = ({ selectedId }) => {
  const [title, setTitle] = useState(""),
    [showModal, setShowModal] = useState(false),
    [viewState, setViewState] = useState([]),
    [table, setTable] = useState(null),
    [iconColor, setIconColor] = useState(""),
    [iconBgColor, setIconBgColor] = useState(""),
    [fieldIcon, setFieldIcon] = useState(""),
    [tags, setTags] = useState(""),
    [tempTags, setTempTags] = useState(""),
    [showWorkFlowModal, setShowWorkFlowModal] = useState(false),
    [showOutsideWorkFlowModal, setShowOutsideWorkFlowModal] = useState(false),
    [dynamicWidth, setDynamicWidth] = useState(90),
    [dynamicRange, setDynamicRange] = useState(99),
    [isHovered, setIsHovered] = useState(null),
    [workFlowEditData, setWorkFlowEditData] = useState([]),
    [transactionTypeStatus, setTransactionTypeStatus] = useState([]),
    [additionalTypeStatus, setAdditionalTypeStatus] = useState([]),
    [defaultSelectedLocation, setDefaultSelectedLocation] = useState("Required: Service Address"),
    [defaultSelectedLocationIcon, setDefaultSelectedLocationIcon] = useState("geo-alt-fill"),
    [defaultSelectedRole, setDefaultSelectedRole] = useState(""),
    [defaultMainNavigation, setDefaultMainNavigation] = useState("")

  const handleViewState = (type, value) => {
    setViewState({ ...viewState, [type]: value })
  }

  const getTransactionDetail = selectedId => {
    return new Promise(async () => {
      await AxiosInstance.get(`/settings/transaction-type/detail/${selectedId}`).then(function (response) {
        if (response.status === 200) {
          const transactionType = response.data.type

          setTitle(transactionType.dealTypeLabel)
          setFieldIcon(transactionType.icon)
          setIconColor(transactionType.iconColor)
          setIconBgColor(transactionType.iconBackgroundColor)
          setTags(transactionType.tags ? transactionType.tags : [])
          setTempTags(transactionType.tags ? transactionType.tags : [])
          setDefaultSelectedLocation(
            transactionType.customFieldsJson.location
              ? transactionType.customFieldsJson.location.label
              : "Required: Service Address"
          )
          setDefaultSelectedLocationIcon(
            transactionType.customFieldsJson.location
              ? transactionType.customFieldsJson.location.icon
              : "geo-alt-fill"
          )
          setDefaultSelectedRole(
            transactionType.customFieldsJson.role ? transactionType.customFieldsJson.role : ""
          )
          setDefaultMainNavigation(
            transactionType.customFieldsJson.main_navigation
              ? transactionType.customFieldsJson.main_navigation
              : "All Jobs / Projects / Sales Opportunities"
          )

          const statusFilteredData = transactionType.statuses.filter(item => !item.additionalType)
          setTransactionTypeStatus(statusFilteredData)

          const additionTypeFilteredData = transactionType.statuses.filter(item => item.additionalType)
          setAdditionalTypeStatus(additionTypeFilteredData)
        }
      })
    })
  }

  const businessRoleTable = () => {
    AxiosInstance.get("/settings/role")
      .then(response => {
        if (response.data.items.length === 0) {
          setTable(null)
        } else {
          setTable(response.data.items)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  const handleFormSubmit = e => {
    e.preventDefault()

    const formData = new FormData(e.target)
    let transactionTypeName = formData.get("transactionTypeName")
    let transactionIconColor = formData.get("icon_font_color")
      ? formData.get("icon_font_color").toLowerCase()
      : iconColor
    let transactionIconBgColor = formData.get("background_color")
      ? formData.get("background_color")
      : iconBgColor

    let customFieldsJson = {
      location: {
        label: defaultSelectedLocation,
        icon: defaultSelectedLocationIcon,
      },
      role: defaultSelectedRole,
      main_navigation: defaultMainNavigation,
    }

    AxiosInstance.post(`/settings/transaction-type/edit/${selectedId}`, {
      dealTypeLabel: transactionTypeName ? transactionTypeName : title,
      icon: fieldIcon,
      tags: tempTags,
      iconColor: transactionIconColor,
      iconBackgroundColor: transactionIconBgColor,
      customFieldsJson: customFieldsJson,
    })
      .then(response => {})
      .catch(error => {
        console.log(error)
      })
  }

  const handleWorkFlowHighlight = workflowid => {
    setIsHovered(workflowid)
  }

  const editWorkFlow = (workflowdata, additionalType) => {
    setWorkFlowEditData(workflowdata)

    if (additionalType) {
      setShowOutsideWorkFlowModal(true)
    } else {
      setShowWorkFlowModal(true)
    }
  }

  const deleteWorkFlow = workflowid => {
    if (workflowid) {
      return new Promise(async () => {
        await AxiosInstance.delete(`/settings/transaction-type/delete-status/${workflowid}`)
          .then(function (response) {
            if (response.status === 200) {
              getTransactionDetail(selectedId)
            }
          })
          .catch(function (error) {
            alert("There is some error Please try after sometime.")
          })
      })
    }
  }

  useEffect(() => {
    businessRoleTable()
    getTransactionDetail(selectedId)
  }, [])

  return (
    <Layout>
      <Seo title={title} />

      <div id="right-section" className="h-100">
        <form method="POST" onSubmit={handleFormSubmit}>
          <div className="row g-0">
            <div className="col-sm-12">
              <div className="pageTitle_Editor">
                <OverlayTrigger overlay={<Tooltip>Back to Settings</Tooltip>}>
                  <Link to={"/settings"} className="goBack">
                    <i className="bi bi-arrow-90deg-left" />
                  </Link>
                </OverlayTrigger>

                <div className="pageTitle_Editor_IconBox">
                  <button
                    type="button"
                    className={`btn btnPageTitle_Editor_Icon color-${iconColor}`}
                    style={{ backgroundColor: iconBgColor }}
                  >
                    <i className={`bi bi-${fieldIcon}`} />
                  </button>
                  <a
                    href="#"
                    className="btn btn-default btnSetWorkFlowIconColor"
                    onClick={() => {
                      setShowModal(true)
                    }}
                  >
                    <i className="bi bi-pencil-fill" />
                  </a>
                </div>
                <input
                  type="text"
                  className="form-control"
                  defaultValue={title}
                  placeholder="Untitled Job Type or Workflow"
                  name="transactionTypeName"
                />

                <OverlayTrigger overlay={<Tooltip>Templates</Tooltip>}>
                  <Link to={"#"} className="btnLinkToTemplate">
                    <i className="bi bi-journals" />
                  </Link>
                </OverlayTrigger>
              </div>
            </div>
          </div>

          <div className="row g-0 pt-4">
            <div className="col-lg-12 mb-3" id="team_panel">
              {isShowState(viewState.role) && (
                <Accordion defaultActiveKey={`${viewState.role !== VIEW_STATE_OPEN ? 1 : 0}`}>
                  <Accordion.Item eventKey="0" className="panel panel-green">
                    <Accordion.Header>
                      <span className="panel-heading-icon">
                        <i className="bi bi-person-fill" />
                      </span>
                      <span className="panel-heading-title">People & Roles</span>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="row row-cols-lg-auto g-2 align-items-center justify-content-center">
                            <div className="col-12">Client Role Label</div>
                            <div className="col-12">
                              <select
                                className="form-select"
                                defaultValue={defaultSelectedRole}
                                onChange={e => {
                                  setDefaultSelectedRole(e.target.value)
                                }}
                              >
                                {table.map((roleData, roleIndex) => (
                                  <option key={roleIndex} value={roleData.id}>
                                    {roleData.role}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="col-12">
                              <Link
                                to={"/settings/business"}
                                className="btnAddNewGreenDashed btnAddNewSettingBusiness"
                              >
                                <i className="bi bi-plus-lg" />
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              )}
            </div>

            <div className="col-lg-12 mb-3">
              <div className="workflowStepsContainer">
                <div className="row">
                  <div className="col-lg-12 mb-3 position-relative text-center py-3">
                    <span className="fs-4">Job Completion Stages / Workflow Steps</span>
                  </div>
                  <div className="col-lg-12">
                    <ul className="nav nav-wizard workflow-wizard">
                      {transactionTypeStatus.map(
                        (data, index) =>
                          data.label &&
                          data.icon && (
                            <li
                              className="SaleStatus_Pending"
                              key={index}
                              style={{ width: `${dynamicWidth / transactionTypeStatus.length}%` }}
                              id={index}
                              onMouseOver={() => handleWorkFlowHighlight(`transactionType${index}`)}
                              onMouseOut={() => setIsHovered(null)}
                            >
                              <span className="workflow-wizard-start-score">
                                {parseFloat(
                                  (index * (dynamicRange / transactionTypeStatus.length) + 1).toFixed(2)
                                )}
                              </span>
                              <a
                                href="#"
                                className={`workflow_step ${
                                  isHovered == `transactionType${index}` ? "highlight_step" : ""
                                }`}
                              >
                                <i className={`bi bi-${data.icon}`} /> <span>{data.label}</span>
                              </a>

                              <a
                                href="#"
                                className="btnEditWorkflowStep"
                                onClick={() => {
                                  editWorkFlow(data, false)
                                }}
                              >
                                <OverlayTrigger overlay={<Tooltip>Edit this step</Tooltip>}>
                                  <span>
                                    <i className="bi bi-pencil-fill" />
                                  </span>
                                </OverlayTrigger>
                              </a>

                              <a
                                href="#"
                                className="btnDeleteWorkflowStep"
                                onClick={() => {
                                  deleteWorkFlow(data.id)
                                }}
                              >
                                <OverlayTrigger overlay={<Tooltip>Delete this step?</Tooltip>}>
                                  <span>
                                    <i className="bi bi-x-lg" />
                                  </span>
                                </OverlayTrigger>
                              </a>

                              <span className="workflow-wizard-end-score">
                                {parseFloat(
                                  ((index + 1) * (dynamicRange / transactionTypeStatus.length)).toFixed(2)
                                )}
                              </span>
                            </li>
                          )
                      )}

                      <li className="SaleStatus_NotStarted">
                        <span className="workflow-wizard-start-score">100</span>

                        <a
                          href="#"
                          className="btnAddNewWorkflowStep"
                          onClick={() => {
                            setShowWorkFlowModal(true)
                          }}
                        >
                          <OverlayTrigger overlay={<Tooltip>Add new Ideal Workflow / Step</Tooltip>}>
                            <span>
                              <i className="bi bi-plus-lg" />
                            </span>
                          </OverlayTrigger>
                        </a>

                        <a href="#" className="workflow_step">
                          <OverlayTrigger
                            overlay={
                              <Tooltip>
                                100 - Project Completed or Raving Fan - Refers You / Brand Advocate
                              </Tooltip>
                            }
                          >
                            <span>
                              <i className="bi bi-trophy-fill" />
                            </span>
                          </OverlayTrigger>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-12 jobLostRevisionTabs">
                    <ul>
                      {additionalTypeStatus.map((data, index) => (
                        <li key={index}>
                          <a href="#">
                            <i className={`bi bi-${data.icon}`} /> <span>{data.label}</span>
                          </a>

                          <a
                            href="#"
                            className="btnEditJobLostRevision"
                            onClick={() => {
                              editWorkFlow(data, true)
                            }}
                          >
                            <OverlayTrigger overlay={<Tooltip>Edit</Tooltip>}>
                              <span>
                                <i className="bi bi-pencil-fill" />
                              </span>
                            </OverlayTrigger>
                          </a>

                          <a
                            href="#"
                            className="btnDeleteJobLostRevision"
                            onClick={() => {
                              deleteWorkFlow(data.id)
                            }}
                          >
                            <OverlayTrigger overlay={<Tooltip>Delete</Tooltip>}>
                              <span>
                                <i className="bi bi-x-lg" />
                              </span>
                            </OverlayTrigger>
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-12 mb-3" id="sellingprocess_panel">
              <div className="row mt-1">
                {transactionTypeStatus.map(
                  (data, index) =>
                    data.label &&
                    data.icon && (
                      <div
                        key={index}
                        className={`col-lg-12 mb-3 TopOfFunnelRow ${
                          isHovered == `transactionType${index}` ? "highlight_step" : ""
                        }`}
                        id={index}
                        onMouseOver={() => handleWorkFlowHighlight(`transactionType${index}`)}
                        onMouseOut={() => setIsHovered(null)}
                      >
                        <Accordion>
                          <Accordion.Item eventKey="0" className="panel panel-white">
                            <Accordion.Header>
                              <div className="WorkflowProcessHeader">
                                <div className="WorkflowProcessHeaderLeft">
                                  <div className="WorkflowProcessHeaderLeftIcon">
                                    <a className="btn btn-default" href="#">
                                      <i className={`bi bi-${data.icon}`} />
                                    </a>
                                  </div>
                                  <div className="WorkflowProcessHeaderRightTitle">
                                    {data.label}{" "}
                                    <a href="#" className="btnEditWorkflowStep">
                                      <OverlayTrigger overlay={<Tooltip>Edit this step</Tooltip>}>
                                        <span>
                                          <i className="bi bi-pencil-fill" />
                                        </span>
                                      </OverlayTrigger>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </Accordion.Header>
                            <Accordion.Body></Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                    )
                )}

                <div className="col-lg-12 mb-3 text-center addNewTopOfFunnelContainer">
                  <OverlayTrigger overlay={<Tooltip>Add Stage / Ideal Workflow Step</Tooltip>}>
                    <button
                      type="button"
                      className={`btn btnAddNewGreenDashed addNewTopOfFunnelDetails`}
                      onClick={() => {
                        setShowWorkFlowModal(true)
                        setWorkFlowEditData([])
                      }}
                    >
                      <i className="bi bi-plus-lg" />
                    </button>
                  </OverlayTrigger>
                </div>

                <div>
                  {additionalTypeStatus.map(
                    (data, index) =>
                      data.label &&
                      data.icon && (
                        <div key={index} className="col-lg-12 mb-3 TopOfFunnelRow">
                          <Accordion>
                            <Accordion.Item eventKey="0" className="panel panel-white">
                              <Accordion.Header>
                                <div className="WorkflowProcessHeader">
                                  <div className="WorkflowProcessHeaderLeft">
                                    <div className="WorkflowProcessHeaderLeftIcon">
                                      <a className="btn btn-default" href="#">
                                        <i className={`bi bi-${data.icon}`} />
                                      </a>
                                    </div>
                                    <div className="WorkflowProcessHeaderRightTitle">
                                      {data.label}{" "}
                                      <a href="#" className="btnEditWorkflowStep">
                                        <OverlayTrigger overlay={<Tooltip>Edit this step</Tooltip>}>
                                          <span>
                                            <i className="bi bi-pencil-fill" />
                                          </span>
                                        </OverlayTrigger>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </Accordion.Header>
                              <Accordion.Body></Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                        </div>
                      )
                  )}

                  <div className="col-lg-12 text-center addNewTopOfFunnelContainer">
                    <OverlayTrigger overlay={<Tooltip>Add Stage Outside Ideal Workflow</Tooltip>}>
                      <button
                        type="button"
                        className={`btn btnAddNewGreenDashed addNewStageOutsideIdealWorkflow`}
                        onClick={() => {
                          setShowOutsideWorkFlowModal(true)
                          setWorkFlowEditData([])
                        }}
                      >
                        <i className="bi bi-plus-circle-dotted" />
                      </button>
                    </OverlayTrigger>
                  </div>
                </div>
              </div>
            </div>

            {isShowState(viewState.moreOption) && (
              <div className="col-lg-12 mb-3" id="more_panel">
                <Accordion defaultActiveKey={`${viewState.moreOption !== VIEW_STATE_OPEN ? 1 : 0}`}>
                  <Accordion.Item eventKey="0" className="panel panel-grey">
                    <Accordion.Header>
                      <span className="panel-heading-icon">
                        <i className="bi bi-three-dots" />
                      </span>
                      <span className="panel-heading-title">More Options</span>
                    </Accordion.Header>
                    <div
                      id="more_container"
                      className="accordion-collapse collapse show"
                      aria-labelledby="more_container"
                    >
                      <Accordion.Body>
                        <div className="row">
                          <div className="col-lg-4">
                            <div className="row">
                              <div className="col-lg-12 mb-3">
                                <div className="colHeading">
                                  <strong>
                                    <i className="bi bi-geo-alt-fill" /> Location
                                  </strong>
                                </div>
                                <div className="dh-block-body">
                                  <Dropdown>
                                    <Dropdown.Toggle
                                      variant="basic"
                                      className="btn btn-default dropdown-toggle"
                                      type="button"
                                    >
                                      <span>
                                        <i className={`bi bi-${defaultSelectedLocationIcon}`}></i>{" "}
                                        {defaultSelectedLocation}
                                      </span>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu data-popper-placement="bottom-start">
                                      <Dropdown.Item
                                        href="#"
                                        onClick={e => {
                                          setDefaultSelectedLocation(e.target.text)
                                          setDefaultSelectedLocationIcon("geo-alt-fill")
                                        }}
                                      >
                                        <i className="bi bi-geo-alt-fill"></i> Required: Service Address
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        href="#"
                                        onClick={e => {
                                          setDefaultSelectedLocation(e.target.text)
                                          setDefaultSelectedLocationIcon("geo-fill")
                                        }}
                                      >
                                        <i className="bi bi-geo-fill"></i> Required: Pickup &amp; Dropoff
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        href="#"
                                        onClick={e => {
                                          setDefaultSelectedLocation(e.target.text)
                                          setDefaultSelectedLocationIcon("crosshair")
                                        }}
                                      >
                                        <i className="bi bi-crosshair"></i> Client comes to us
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        href="#"
                                        onClick={e => {
                                          setDefaultSelectedLocation(e.target.text)
                                          setDefaultSelectedLocationIcon("globe")
                                        }}
                                      >
                                        <i className="bi bi-globe"></i> Not Applicable
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-4">
                            <div className="row">
                              <div className="col-lg-12 mb-3">
                                <div className="colHeading">
                                  <strong>
                                    <i className="bi bi-tags-fill" /> Default Tags
                                  </strong>
                                </div>
                                <div className="dh-block-body">
                                  <div className="bootstrap-tagsinput">
                                    <Tags
                                      tags={tags}
                                      setTags={setTags}
                                      tempTags={tempTags}
                                      setTempTags={setTempTags}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-4">
                            <div className="row">
                              <div className="col-lg-12 mb-3">
                                <div className="colHeading">
                                  <strong>
                                    <i className="bi bi-list" /> Main Navigation
                                  </strong>
                                </div>
                                <div className="dh-block-body">
                                  <div className="row row-cols-lg-auto g-1 align-items-center">
                                    <div className="col-12">
                                      <i className="bi bi-pin-fill" /> Pin to
                                    </div>
                                    <div className="col-12">
                                      <select
                                        className="form-select"
                                        name="navigation_pin"
                                        id="navigation_pin"
                                        tabIndex={-98}
                                        defaultValue={defaultMainNavigation}
                                        onChange={e => {
                                          setDefaultMainNavigation(e.target.value)
                                        }}
                                      >
                                        <option value="Tasks & Projects">
                                          All Jobs / Projects / Sales Opportunities
                                        </option>
                                        <option value="Contacts">Contacts</option>
                                        <option value="Marketing">
                                          {" "}
                                          Marketing Campaigns &amp; Experiments
                                        </option>
                                        <option value="Accounting">Accounting</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Accordion.Body>
                    </div>
                  </Accordion.Item>
                </Accordion>
              </div>
            )}

            <WorkFlowIconColorModal
              showModal={showModal}
              setShowModal={setShowModal}
              iconColor={iconColor}
              setIconColor={setIconColor}
              iconBgColor={iconBgColor}
              setIconBgColor={setIconBgColor}
              fieldIcon={fieldIcon}
              setFieldIcon={setFieldIcon}
            />

            <IdealWorkFlowModel
              showWorkFlowModal={showWorkFlowModal}
              setShowWorkFlowModal={setShowWorkFlowModal}
              transactionTypeId={selectedId}
              workFlowEditData={workFlowEditData}
              getTransactionDetail={getTransactionDetail}
            />

            <OutsideIdealWorkFlowModel
              showOutsideWorkFlowModal={showOutsideWorkFlowModal}
              setShowOutsideWorkFlowModal={setShowOutsideWorkFlowModal}
              transactionTypeId={selectedId}
              workFlowEditData={workFlowEditData}
              getTransactionDetail={getTransactionDetail}
            />

            <div className="col-lg-12 mb-3 text-center footer-action">
              <OverlayTrigger overlay={<Tooltip>People & Roles</Tooltip>}>
                <button
                  type="button"
                  className={`footer-action-btn btnSettingsJobtypePeopleRoles ${
                    isShowState(viewState.role) ? " active" : ""
                  }`}
                  onClick={() => {
                    handleViewState(
                      "role",
                      typeof viewState.role === "undefined" || viewState.role === VIEW_STATE_HIDE
                        ? VIEW_STATE_OPEN
                        : VIEW_STATE_HIDE
                    )
                  }}
                >
                  <i className="bi bi-person-fill" />
                </button>
              </OverlayTrigger>{" "}
              <OverlayTrigger overlay={<Tooltip>More Options</Tooltip>}>
                <button
                  type="button"
                  className={`footer-action-btn btnSettingsJobtypeMoreOptions ${
                    isShowState(viewState.moreOption) ? " active" : ""
                  }`}
                  onClick={() => {
                    handleViewState(
                      "moreOption",
                      typeof viewState.moreOption === "undefined" || viewState.moreOption === VIEW_STATE_HIDE
                        ? VIEW_STATE_OPEN
                        : VIEW_STATE_HIDE
                    )
                  }}
                >
                  <i className="bi bi-three-dots" />
                </button>
              </OverlayTrigger>
            </div>

            <div className="col-lg-12 text-center ">
              <button type="submit" className="btn btn-primary btn-lg">
                <i className="bi bi-check2" /> Save Settings
              </button>{" "}
              <OverlayTrigger overlay={<Tooltip>History</Tooltip>}>
                <button type="button" className="btn btn-default btn-lg btnOpenChatDock">
                  <i className="bi bi-arrow-counterclockwise" />
                </button>
              </OverlayTrigger>
            </div>
          </div>
        </form>
      </div>
    </Layout>
  )
}

export default Detail
